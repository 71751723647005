import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Keys } from '@/constants/general/storage';
import { RegistrationTypeDto } from '@/constants/sign-up';

interface AppSate {
  isLoading: boolean;
  activeActionsMenu: string | null;
  filters: {
    courses: string[];
    pathways: string[];
  };
  registrationType: RegistrationTypeDto;
  summativeAssessment: {
    isActive: boolean;
    leaveAssessmentModal: {
      isOpen: boolean;
      leaveTo: string;
    };
  };
}

const initialState: AppSate = {
  isLoading: false,
  activeActionsMenu: null,
  filters: {
    courses: [],
    pathways: [],
  },
  registrationType: {
    isActive: false,
  },
  summativeAssessment: {
    isActive: false,
    leaveAssessmentModal: {
      isOpen: false,
      leaveTo: '',
    },
  },
};

const appSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<AppSate['isLoading']>) => {
      state.isLoading = action.payload;
    },
    setActiveActionsMenu: (state, action: PayloadAction<AppSate['activeActionsMenu']>) => {
      state.activeActionsMenu = action.payload;
    },
    setCourseFilters: (state, action: PayloadAction<{ courseIds: string | string[]; hasNewUpdates: boolean }>) => {
      const { courseIds, hasNewUpdates } = action.payload;

      if (courseIds.length === 0) {
        state.filters.courses = [];
        localStorage.removeItem(Keys.PINNED_COURSES);
        return;
      }

      let parsedCourseIds = courseIds;

      if (typeof courseIds === 'string') {
        parsedCourseIds = [courseIds];
      }

      if (state.filters.courses.some((course) => parsedCourseIds.includes(course))) {
        if (hasNewUpdates) {
          state.filters.courses = state.filters.courses.filter((course) => !parsedCourseIds.includes(course));
        }
      } else {
        state.filters.courses.push(...parsedCourseIds);
      }

      if (hasNewUpdates) {
        if (state.filters.courses.length > 0) {
          localStorage.setItem(Keys.PINNED_COURSES, state.filters.courses.join(','));
        } else {
          localStorage.removeItem(Keys.PINNED_COURSES);
        }
      }
    },
    setPathwayFilters: (state, action: PayloadAction<{ pathwayIds: string | string[]; hasNewUpdates: boolean }>) => {
      const { pathwayIds, hasNewUpdates } = action.payload;

      if (pathwayIds.length === 0) {
        state.filters.pathways = [];
        localStorage.removeItem(Keys.PINNED_PATHWAYS);
        return;
      }

      let parsedPathwayIds = pathwayIds;

      if (typeof pathwayIds === 'string') {
        parsedPathwayIds = [pathwayIds];
      }

      if (state.filters.pathways.some((pathway) => parsedPathwayIds.includes(pathway))) {
        if (hasNewUpdates) {
          state.filters.pathways = state.filters.pathways.filter((pathway) => !parsedPathwayIds.includes(pathway));
        }
      } else {
        state.filters.pathways.push(...parsedPathwayIds);
      }

      if (hasNewUpdates) {
        if (state.filters.pathways.length > 0) {
          localStorage.setItem(Keys.PINNED_PATHWAYS, state.filters.pathways.join(','));
        } else {
          localStorage.removeItem(Keys.PINNED_PATHWAYS);
        }
      }
    },
    setRegistrationType: (state, action: PayloadAction<RegistrationTypeDto>) => {
      state.registrationType = action.payload;
    },
    setIsAssessmentActive: (state, action: PayloadAction<boolean>) => {
      state.summativeAssessment.isActive = action.payload;
    },
    setLeaveAssessmentModal: (state, action: PayloadAction<{ isOpen: boolean; leaveTo: string }>) => {
      state.summativeAssessment.leaveAssessmentModal = action.payload;
    },
  },
});

const { actions, reducer } = appSlice;

export const {
  setIsLoading,
  setActiveActionsMenu,
  setCourseFilters,
  setPathwayFilters,
  setRegistrationType,
  setIsAssessmentActive,
  setLeaveAssessmentModal,
} = actions;

export default reducer;
