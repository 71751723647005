export enum RegistrationType {
  Free = 'free',
  Professional = 'professional',
  Emergn = 'emergn',
  Beta = 'beta',
  Enterprise = 'enterprise',
}

export type RegistrationTypeDto = {
  type?: RegistrationType;
  isActive: boolean;
};
