export enum ErrorMessage {
  SOMETHING_WENT_WRONG = 'An unexpected error has occurred. Please try again.',
  NOT_AUTHORIZED = 'You are not authorized to view this page.',
  VALIDATION_ERROR = 'Invalid data. Please check the fields marked in red and try again.',
}

export const errorMessageCodeMap: { [key: number]: ErrorMessage } = {
  400: ErrorMessage.SOMETHING_WENT_WRONG,
  500: ErrorMessage.SOMETHING_WENT_WRONG,
  401: ErrorMessage.NOT_AUTHORIZED,
  403: ErrorMessage.NOT_AUTHORIZED,
};
