import { baseApiSlice } from './base-api-slice';

import { Course } from '@/interfaces';
import { UserFavoritesType } from '@/constants/explore';

export const exploreApiSlice = baseApiSlice
  .enhanceEndpoints({ addTagTypes: ['CMSCourses', 'UserFavorites'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCMSCourses: builder.query<Course[], void>({
        query: () => 'explore/get-courses',
        transformResponse: (response: { data: Course[] }) => response.data,
        providesTags: ['CMSCourses'],
      }),
      getUserFavorites: builder.query<UserFavoritesType, void>({
        query: () => 'explore/get-user-favorites',
        transformResponse: (response: { data: UserFavoritesType }) => response.data,
        providesTags: ['UserFavorites'],
      }),
    }),
  });

export const { useGetCMSCoursesQuery, useGetUserFavoritesQuery } = exploreApiSlice;
