import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserSettings } from '../interfaces';

interface UserState {
  user: User;
}

const initialState: UserState = {
  user: {
    id: '',
    email: '',
    name: '',
    surname: '',
    settings: {
      b2cAccess: false,
    },
    role: '',
    isActivated: false,
    clientId: '',
    clientName: '',
    courseModules: [],
    pathwayModules: [],
    checklist: 'intro',
    teams: [],
    type: {
      isBetaUser: false,
      isEmergnUser: false,
      isB2cUser: {
        professional: false,
        free: false,
        trial: false,
      },
      isProductManager: false,
    },
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.user.name = action.payload;
    },
    setUserSurname: (state, action: PayloadAction<string>) => {
      state.user.surname = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.user.email = action.payload;
    },
    updateUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.user.settings = action.payload;
    },
    setUserChecklistStatus: (state, action: PayloadAction<'intro' | 'path' | 'createTeam' | 'survey' | 'result'>) => {
      state.user.checklist = action.payload;
    },
    addUserTeams: (state, action: PayloadAction<string>) => {
      state.user.teams.push(action.payload);
    },
    setUserFavoriteBooks: (state, action: PayloadAction<string[]>) => {
      state.user.favoriteBooks = action.payload;
    },
    setUserFavoriteChapters: (state, action: PayloadAction<string[]>) => {
      state.user.favoriteChapters = action.payload;
    },
    setUserFavoriteTechniques: (state, action: PayloadAction<string[]>) => {
      state.user.favoriteTechniques = action.payload;
    },
    setUserFavoriteInsights: (state, action: PayloadAction<string[]>) => {
      state.user.favoriteInsights = action.payload;
    },
    setUserFavoriteTools: (state, action: PayloadAction<string[]>) => {
      state.user.favoriteTools = action.payload;
    },
  },
});

const { actions, reducer } = userSlice;

export const {
  setUser,
  setUserName,
  setUserSurname,
  setUserEmail,
  updateUserSettings,
  setUserChecklistStatus,
  addUserTeams,
  setUserFavoriteBooks,
  setUserFavoriteChapters,
  setUserFavoriteTechniques,
  setUserFavoriteInsights,
  setUserFavoriteTools,
} = actions;

export default reducer;
