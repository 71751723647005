import { useEffect, useRef } from 'react';

export const useClickOutside = (handler: () => void) => {
  const clickOutsideRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (clickOutsideRef.current && !clickOutsideRef.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handler]);

  return clickOutsideRef;
};
