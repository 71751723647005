import { useCallback, useEffect, useRef, useState } from 'react';

export const useOptionsMenu = (isAccordion: boolean, buttonTopDistance: number, widthAdjustmentValue: number) => {
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const optionsMenuRef = useRef<any>(null);

  const updateMenuPosition = useCallback(() => {
    let currentMenuPosition = menuPosition;

    if (optionsMenuRef.current) {
      // list menu max width and height
      const listWidth = 240;
      const listHeight = 140;

      let finalElement = optionsMenuRef.current;

      if (isAccordion) {
        finalElement = optionsMenuRef.current.querySelector('ids-button');

        if (!finalElement) return;
      }

      currentMenuPosition = {
        x: finalElement.getBoundingClientRect().left || menuPosition.x,
        y: finalElement.getBoundingClientRect().top + buttonTopDistance || menuPosition.y,
      };

      // get viewport width and height
      const { clientWidth, clientHeight } = document.documentElement;
      const optionsMenuRect = finalElement.getBoundingClientRect();

      // if list menu is out of viewport, adjust position
      if (optionsMenuRect.right + listWidth > clientWidth) {
        currentMenuPosition.x -= listWidth - widthAdjustmentValue;
      }
      if (optionsMenuRect.bottom + listHeight > clientHeight) {
        currentMenuPosition.y -= listHeight;
      }
    }
    setMenuPosition(currentMenuPosition);
  }, [menuPosition, isAccordion, buttonTopDistance, widthAdjustmentValue]);

  useEffect(() => {
    window.addEventListener('resize', updateMenuPosition);

    return () => {
      window.removeEventListener('resize', updateMenuPosition);
    };
  }, [menuPosition, updateMenuPosition]);

  return { updateMenuPosition, optionsMenuRef, menuPosition };
};
