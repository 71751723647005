import { Environments } from '../../interfaces/general/environment-variables';

/**
 * Method that returns the environment based on the URL
 * @param envs
 */
const getEnvByUrl = (envs: string[]): string => {
  if (typeof window === 'undefined') return '';

  return envs.find((env) => window.origin.includes(env)) || 'production';
};

/**
 * Method that returns the backend URL based on the environment
 */
export const getBackendURL = () => {
  // getEnvByUrl() is just a safe guard in case the currentEnvironment is not set
  const env = process.env.currentEnvironment || getEnvByUrl(Object.values(Environments));

  switch (env) {
    case Environments.LOCAL: {
      return 'http://localhost:3000/api/';
    }
    case Environments.DEV: {
      return 'https://dev-api.vfq.emergn.com/api/';
    }
    case Environments.QA: {
      return 'https://qa-api.vfq.emergn.com/api/';
    }
    case Environments.STAGE: {
      return 'https://stage-api.vfq.emergn.com/api/';
    }
    case Environments.POC: {
      return 'https://poc-api.valueflowquality.net/api/';
    }
    case Environments.PRODUCTION: {
      return 'https://api.vfq.emergn.com/api/';
    }
    default: {
      return 'https://api.vfq.emergn.com/api/';
    }
  }
};

/**
 * Method that returns the registration URL based on the environment
 */
export const getRegistrationURL = (userLink: string) => {
  switch (process.env.currentEnvironment) {
    case Environments.LOCAL: {
      return `http://localhost:3005/app/sign-up/${userLink}`;
    }
    case Environments.DEV: {
      return `https://dev.vfq.emergn.com/app/sign-up/${userLink}`;
    }
    case Environments.QA: {
      return `https://qa.vfq.emergn.com/app/sign-up/${userLink}`;
    }
    case Environments.STAGE: {
      return `https://stage.vfq.emergn.com/app/sign-up/${userLink}`;
    }
    case Environments.PRODUCTION: {
      return `https://praxis.emergn.com/app/sign-up/${userLink}`;
    }
    default: {
      return `https://praxis.emergn.com/app/sign-up/${userLink}`;
    }
  }
};
