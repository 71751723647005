import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

type QueryParams = Record<string, string | boolean>;

export function useQueryParams() {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  // Get a new searchParams string by merging the current
  // searchParams with a provided key/value pair
  const createQueryString = useCallback(
    (query: QueryParams): string => {
      const params = new URLSearchParams(searchParams || '');
      for (const [name, value] of Object.entries(query)) {
        params.set(name, value.toString());
      }

      return params.toString();
    },
    [searchParams],
  );

  const updateUrlQuery = useCallback(
    (query: QueryParams): void => {
      if (pathname) {
        router.push(pathname + '?' + createQueryString(query));
      }
    },
    [pathname, router, createQueryString],
  );

  return { updateUrlQuery, searchParams };
}
