'use client';

import React from 'react';

type Notification = {
  isOpen: boolean;
  heading: string;
  description: string;
  variant: 'success' | 'critical';
  customActionLabel?: string;
  customAction?: Function;
};

export const emptyNotification: Notification = {
  isOpen: false,
  heading: '',
  description: '',
  variant: 'success',
  customActionLabel: '',
  customAction: () => {},
};

export const NotificationContext = React.createContext({
  notification: emptyNotification,
  openSuccessNotification: (message: string, customActionLabel?: string, customAction?: Function, customTitle?: string) => {
    console.log('openSuccessNotification', message, customActionLabel, customAction, customTitle);
  },
  openErrorNotification: (message: string, customActionLabel?: string, customAction?: Function, customTitle?: string) => {
    console.log('openErrorNotification', message, customActionLabel, customAction, customTitle);
  },
  cleanNotification: () => {},
});

export const useNotification = () => React.useContext(NotificationContext);
