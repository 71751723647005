import { useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';

import { AppRoutes } from '@/constants';
import { setLeaveAssessmentModal } from '@/redux/app-slice';

import { useAppSelector, useAppDispatch } from './redux-hook';

export const useLinkInterceptor = () => {
  const router = useRouter();
  const pathname = usePathname();
  const dispatch = useAppDispatch();

  const {
    summativeAssessment: { isActive: isAssessmentActive },
  } = useAppSelector((state) => state.appState);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const anchor = target.closest('a');

      if (anchor?.href && anchor.origin === window.location.origin && !anchor.download) {
        e.preventDefault();

        if (pathname === AppRoutes.SummativeAssessment && isAssessmentActive) {
          dispatch(setLeaveAssessmentModal({ isOpen: true, leaveTo: anchor.href }));
          return;
        }

        router.push(anchor.href);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [router, pathname, isAssessmentActive, dispatch]);

  return null;
};
