import { ModelType } from '@/constants/explore';
import { useAppDispatch, useAppSelector } from './redux-hook';

import {
  setUserFavoriteBooks,
  setUserFavoriteChapters,
  setUserFavoriteInsights,
  setUserFavoriteTechniques,
  setUserFavoriteTools,
} from '@/redux/user-slice';
import {
  toggleFavoriteBook,
  toggleFavoriteChapter,
  toggleFavoriteInsight,
  toggleFavoriteTechnique,
  toggleFavoriteTool,
} from '@/server-actions/explore-page';
import { exploreApiSlice } from '@/redux/api/explore-api-slice';

export const useUserFavorites = (modelType: ModelType) => {
  const dispatch = useAppDispatch();

  const {
    favoriteBooks = [],
    favoriteChapters = [],
    favoriteInsights = [],
    favoriteTechniques = [],
    favoriteTools = [],
  } = useAppSelector((state) => state.user.user);

  const userFavoriteItems = {
    book: favoriteBooks,
    chapter: favoriteChapters,
    insight: favoriteInsights,
    technique: favoriteTechniques,
    tool: favoriteTools,
  }[modelType];

  const revalidateUserFavorites = () => dispatch(exploreApiSlice.util.invalidateTags(['UserFavorites']));

  const toggleUserFavoriteItem = async (type: ModelType, itemId: string) => {
    const updatedFavoriteItems: string[] = userFavoriteItems.includes(itemId)
      ? userFavoriteItems.filter((favoriteItemId: string) => favoriteItemId !== itemId)
      : [...userFavoriteItems, itemId];

    switch (type) {
      case ModelType.Book: {
        dispatch(setUserFavoriteBooks(updatedFavoriteItems));
        await toggleFavoriteBook({ bookId: itemId });
        break;
      }
      case ModelType.Chapter: {
        dispatch(setUserFavoriteChapters(updatedFavoriteItems));
        await toggleFavoriteChapter({ chapterId: itemId });
        break;
      }
      case ModelType.Insight: {
        dispatch(setUserFavoriteInsights(updatedFavoriteItems));
        await toggleFavoriteInsight({ insightId: itemId });
        break;
      }
      case ModelType.Technique: {
        dispatch(setUserFavoriteTechniques(updatedFavoriteItems));
        await toggleFavoriteTechnique({ techniqueId: itemId });
        break;
      }
      case ModelType.Tool: {
        dispatch(setUserFavoriteTools(updatedFavoriteItems));
        await toggleFavoriteTool({ toolId: itemId });
        break;
      }
    }

    revalidateUserFavorites();
  };

  return {
    userFavoriteItems,
    toggleUserFavoriteItem,
  };
};
