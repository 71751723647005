import { RegistrationState } from '../../interfaces/sign-up';

export const defaultState: RegistrationState = {
  lastName: '',
  firstName: '',
  email: '',
  userId: '',
  password: '',
  confirmPassword: '',
  showPassword: false,
  showConfirmPassword: false,
  signUpAgreement: false,
  marketingOptIn: false,
  showTooltip: false,
  errorAlert: false,
  isCreated: false,
};

export const defaultPristineValues = {
  lastName: true,
  firstName: true,
  email: true,
  password: true,
  confirmPassword: true,
  signUpAgreement: true,
  marketingOptIn: true,
};
